<template>
  <div class="fund-settle-container">
    <div class="fund-settle-head">
      <a-input class="search_item" placeholder="请输入公司名称" style="width: 260px" allowClear v-model.trim="query.name" />
      <a-button type="primary" class="search-btn" @click="handleSearch">
        查询
      </a-button>
    </div>
    <div class="fund-settle-main">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="partnerName" label="部署平台"> </el-table-column>
          <!-- <el-table-column prop="registerTime" label="注册时间"> </el-table-column> -->
          <el-table-column prop="company" label="公司名称"> </el-table-column>
          <el-table-column prop="email" label="登录邮箱" width="180"> </el-table-column>
          <el-table-column prop="aggregateAmount" label="总入账金额">
            <template slot-scope="{row}">
              <div>{{ moneyText }}{{ numFormat(row.aggregateAmount || 0, 0) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="residualAmount" label="剩余可用金额">
            <template slot-scope="{row}">
              <div>{{ moneyText }}{{ numFormat(row.residualAmount || 0, 0) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <div class="action-slot" slot-scope="{row}">
              <span class="btn" @click="handleAddFund(row)">新建入账信息</span>
              <span class="btn" @click="handleEditFund(row)">查看入账信息</span>
            </div>
          </el-table-column>
        </el-table>
      </a-spin>
      <a-pagination
        class="pagination"
        v-if="tableData.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
      <m-empty v-else style="height: 341px" imgHeight="176px">
        <template #description>暂时没有任何数据 </template>
      </m-empty>
    </div>
    <FundSettleModal
      v-if="addFundVisible"
      :fundInfo="fundInfo"
      @handleChange="handleSearch"
      @modalCancel="addFundVisible = false"
      :isAdd="isAdd"
      :visible="addFundVisible"/>
  </div>
</template>

<script>
import FundSettleModal from '../components/FundSettleModal'
import { numFormat } from '@/utils/dealNumber'
import {
  fundPage
} from '@/apiForManage/mediaAccount'
import mixDate from '@/mixins/initDate'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'

export default {
  mixins: [mixDate, rateAndMoneyMethod],
  components: { FundSettleModal },
  data () {
    return {
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      addFundVisible: false,
      isAdd: 0,
      fundInfo: {},
      tableData: [],
      isLoading: false,
      sourceInfo: {},
      configPlaceVisible: false
    }
  },
  created () {
    this.getTableList()
  },
  methods: {
    numFormat,
    handleEditFund (item) {
      this.isAdd = 3
      this.fundInfo = JSON.parse(JSON.stringify(item))
      this.addFundVisible = true
    },
    handleSearch () {
      this.query.page = 1
      this.getTableList()
    },
    handleAddFund (item) {
      this.isAdd = 1
      this.fundInfo = JSON.parse(JSON.stringify(item))
      this.addFundVisible = true
    },
    // 修改父表page
    handleChangePage (page) {
      this.query.page = page
      this.getTableList()
    },
    // 修改父表limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableList()
    },
    async getTableList () {
      this.isLoading = true
      const { data = {} } = await fundPage(this.query)
      const { total = 0, items = [] } = data
      this.total = total
      this.tableData = items
      this.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.fund-settle-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .fund-settle-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: @mediumMargin;
    .search_item{
      margin-right: 10px;
    }
    .search-btn{
      width: 88px;
    }
  }
  .fund-settle-main {
    ::v-deep {
      .el-table__empty-block {
        border-bottom: 1px solid #EBEEF5;
      }
    }
    background-color: #fff;
    border-radius: @mediumRadius;
    padding: 0 @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    padding-top: calc(@smallMargin - @thPadding);
    .pagination{
      padding: @smallMargin 0;
    }
  }
}
.action-slot {
  user-select: none;
  .btn {
    color: @primary-color;
    cursor: pointer;
  }
  span {
    margin-right: 15px;
  }
}
</style>
